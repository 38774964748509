.texto-empresa {
    padding-left: 15px;
    font-size: 15px;
    color: #3E3C3C;
}

.container-items-empresa {
    margin-bottom: 80px;

    .container-img {
        padding-right: 15px;
    }

    .container-describe {
        .titulo {
            color: #3F3D3D;
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .descricao {
            font-size: 15px;
            color: #3F3D3D;
        }
    }

}
