.btn-whatsapp {
    position: fixed;
    right: 15px;
    bottom: 20px;
    z-index: 3;
    opacity: 1;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: column;
    @media (max-width: 991px) {
        width: 50px;
        height: 50px;
        bottom: 5px !important;
    }
    a {
        margin: 5px 0px;
        color: #fff;
        transition: all 0.5s ease;
        border-radius: 48px;
        background-color: #26d367;
        border: 1px #26d367 solid;
        font-size: 15px;
        line-height: 16px;
        padding: 10px 25px;
        text-decoration: none !important;
        @media (max-width: 991px) {
            border-radius: 50% !important;
            font-size: 26px !important;
            padding: 8.5px 12.63px !important;
            text-align: center !important;
        }

        .icon {
            font-size: 20px !important;
        }

        &:hover,
        &:focus {
            text-decoration: none !important;
            background-color: #fff;
            color: #26d367;
            transition: all 0.5s ease;
            i {
                color: #26d367;
                transition: all 0.5s ease;
            }
        }
    }
}

.btn-whatsapp-pulse {
    animation-name: pulse-whatsapp;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}

@keyframes pulse-whatsapp {
    0% {
        box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
    }
    80% {
        box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
    }
}
