.banner-image-secundario {
    height: 200px !important;

    @media (max-width: 900px) {
        height: 100px !important;
    }
}

.carousel-home {
    margin-bottom: 50px;
    height: 375px !important;

    @media (max-width: 900px) {
        margin-bottom: 40px;

        height: 100% !important;
    }

    .carousel-indicators {
        li {
            height: 11px;
            width: 11px;
            border-radius: 100%;
        }
    }

    .icon-prev {
        margin-right: 230px;
    }

    .icon-next {
        margin-left: 230px;
    }

    .carousel-inner {
        .carousel-item {
            position: relative;

            .content-banner-button {
                position: absolute;
                bottom: 25% !important;
                margin-left: 15%;

                @media (max-width: 768px) {
                    top: 5px;
                }

                .btn-banner {
                    background: #ffa200;
                    color: white;
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: 600;
                    padding: 8px 25px;

                    @media (max-width: 768px) {
                        display: none;
                    }
                }
            }

            .content-banner {
                position: absolute;
                top: calc(50% - 97px);
                margin-left: 15%;

                @media (max-width: 768px) {
                    top: 5px;
                }

                .descricao {
                    font-size: 42px;
                    font-weight: bolder;
                    color: white;
                    line-height: 45px;
                    margin-bottom: 25px;
                    text-transform: uppercase;

                    @media (max-width: 767px) {
                        font-size: 25px;
                        line-height: 27px;
                        margin-bottom: 0px;
                    }
                }

                .btn-banner {
                    background: #ffa200;
                    color: white;
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: 600;
                    padding: 8px 25px;

                    @media (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
    }
}

.container-categorias {
    text-align: center;
    margin-bottom: 20px;

    // justify-content: center;

    &:hover {
        .imagem-categorias {
            background-position-y: -140px;
        }
    }

    .imagem-categorias {
        height: 80px;
        width: 80px;
        border-radius: 100%;
        -webkit-box-shadow: 0px 10px 19px -13px rgba(0, 0, 0, 0.63);
        -moz-box-shadow: 0px 10px 19px -13px rgba(0, 0, 0, 0.63);
        box-shadow: 0px 10px 19px -13px rgba(0, 0, 0, 0.63);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px auto;
        // background-image: url('imgs/alarme.png');
        background-position: center;
        transition: all 0.2s ease;
        background-color: white;
    }

    .titulo-categorias {
        color: black;

        &.white {
            color: #fff;
        }

        font-weight: 600;
    }
}

.container-titulo {
    line-height: 35px;
    color: #0c0100;
    margin-bottom: 35px;
    // text-transform: uppercase;

    @media (max-width: 900px) {
        line-height: 25px;
    }

    .nv-1 {
        text-align: center;
        font-size: 25px;

        // @media (max-width: 900px) {
        //     font-size: 3px;
        // }
    }

    .nv-2 {
        text-align: center;
        font-size: 39px;
        font-weight: 900;

        @media (max-width: 900px) {
            font-size: 25px;
        }
    }

    @media (max-width: 900px) {
        margin-bottom: 40px;
    }
}

.produtos-container-home {
    border-radius: 10px;
    margin-bottom: 10px;
    will-change: transform;
    transition: 0.5s;
    background-color: #fbfbfb;
    // padding-top: 5px;

    // @media (max-width: 900px) {
    //     margin-bottom: 25px;
    // }

    .produto-imagem-container {
        padding: 10px;

        img {
            width: 100%;
            height: 250px;
            background-color: white;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            object-fit: contain;

            @media (max-width: 900px) {
                height: 180px;
            }
        }

        .sem-imagem {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 11px;
            background-color: #f2f2f2;
            color: #a8a8a8;
            text-align: center;
            height: 100%;

            i {
                font-size: 30px;
            }
        }
    }

    .descricao-container {
        padding: 15px;

        .titulo {
            text-align: center;
            color: black;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            //margin-bottom: 20px;
            text-transform: capitalize;
            min-height: 50px;

            @media (max-width: 900px) {
                min-height: 70px;
            }
        }

        .price {
            text-align: center;
            color: #e3342f;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            min-height: 40px;
        }

        .saiba-mais {
            margin-top: 20px;
            text-align: center;
            text-decoration: none !important;
            color: black;
            font-size: 14px;
            margin-bottom: 15px;
        }

        .saiba-mais-guest {
            margin-top: 20px;
            text-align: center;
            text-decoration: none !important;
            font-size: 14px;
            margin-bottom: 15px;

            a {
                color: #e3342f;
            }
        }
    }
}

.carousel-control-prev {
    width: 30px;
    height: 30px;
    color: white;
    background: transparent;
    font-size: 17px;
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    position: absolute;
    top: calc(50% - 15px);
    left: 0px;
    z-index: 999;
    opacity: 1 !important;

    @media (max-width: 768px) {
        left: 0px;
    }
}

.carousel-control-next {
    width: 30px;
    height: 30px;
    color: white;
    font-size: 17px;
    border-radius: 100%;
    background: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    position: absolute;
    top: calc(50% - 15px);
    right: 0px;
    z-index: 999;
    opacity: 1 !important;

    @media (max-width: 768px) {
        right: 0px;
    }
}

.prev-wrapper-novidade {
    width: 30px;
    height: 30px;
    color: #c5004a;
    background: white;
    font-size: 17px;
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    position: absolute;
    top: calc(50% - 15px);
    left: 0px;

    z-index: 999;

    @media (max-width: 768px) {
        left: 0px;
    }
}

.next-wrapper-novidade {
    width: 30px;
    height: 30px;
    color: #c5004a;
    font-size: 17px;
    border-radius: 100%;
    background: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    position: absolute;
    top: calc(50% - 15px);
    right: 0px;
    text-align: right;
    z-index: 999;

    @media (max-width: 768px) {
        right: 0px;
    }

    // &:hover {
    //     background: #c5004a;
    //     color: #fff;
    // }
}

.prev-wrapper-produto {
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: #c5004a;
    background: white;
    font-size: 17px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    position: absolute;
    top: calc(50% - 15px);
    left: 0px;
    z-index: 999;

    @media (max-width: 768px) {
        left: 0px;
    }
}

.next-wrapper-produto {
    width: 30px;
    height: 30px;
    color: #c5004a;
    font-size: 17px;
    border-radius: 100%;
    background: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    position: absolute;
    top: calc(50% - 15px);
    right: 0px;
    text-align: right;
    z-index: 999;

    @media (max-width: 768px) {
        right: 0px;
    }
}

.prev-wrapper-blog {
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: #c5004a;
    background: white;
    font-size: 17px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    position: absolute;
    top: calc(50% - 15px);
    left: 0px;
    z-index: 999;

    @media (max-width: 768px) {
        left: 0px;
    }
}

.next-wrapper-blog {
    width: 30px;
    height: 30px;
    color: #c5004a;
    font-size: 17px;
    border-radius: 100%;
    background: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    position: absolute;
    top: calc(50% - 15px);
    right: 0px;
    text-align: right;
    z-index: 999;

    @media (max-width: 768px) {
        right: 0px;
    }
}

.padding-carousel {
    padding: 0 40px;

    @media (max-width: 900px) {
        padding: 0px !important;
    }
}

.blogs-container-home {
    border-radius: 10px;
    will-change: transform;
    transition: 0.5s;
    background-color: white;
    border: 1px solid #dedede;

    @media (max-width: 900px) {
        margin-bottom: 25px;
    }

    .blog-imagem-container {
        height: 200px;
        border-radius: 10px 10px 0px 0px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .sem-imagem {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 11px;
            background-color: #f2f2f2;
            color: #a8a8a8;
            text-align: center;
            height: 100%;

            i {
                font-size: 30px;
            }
        }
    }

    .descricao-container {
        padding: 15px;

        .titulo {
            text-align: left;
            color: black;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            min-height: 30px;
            text-transform: capitalize;
        }

        .infos {
            text-align: left;
            color: #e3342f;
            font-size: 12px;
            line-height: 17px;
            min-height: 20px;
            text-transform: capitalize;
        }

        .subtitulo {
            text-align: left;
            color: black;
            font-size: 13px;
            line-height: 17px;
            //margin-bottom: 20px;
            text-transform: lowercase;
            min-height: 190px !important;
        }

        .saiba-mais {
            margin-top: 20px;
            text-align: left;
            text-decoration: none !important;
            font-size: 14px;

            a {
                color: #e3342f;
            }
        }
    }
}

.botao-veja-mais {
    // margin: 80px 0;
    background-color: #e3342f;
    padding: 15px 60px;
    font-weight: 600;
    font-size: 15px;
    color: white;
    border-radius: 3px;

    &:hover {
        color: white;
    }
}

.galeria-novidades {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% / 3);
    max-height: 650px !important;

    @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 0px;
    }

    .container-imagem {
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    &.pad-5 {
        padding-right: 5px;
        padding-bottom: 5px;
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: transform 1s, filter 0.5s ease-in-out;
        transform-origin: center center;
        filter: brightness(50%);

        &:hover {
            filter: brightness(100%);
            transform: scale(1.3);
        }
    }

    .banners-novidades {
        padding-right: 5px;
        padding-bottom: 5px;
        width: 50%;
        float: left;

        .pb-5 {
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: transform 1s, filter 0.5s ease-in-out;
            transform-origin: center center;
            filter: brightness(50%);

            &:hover {
                filter: brightness(100%);
                transform: scale(1.3);
            }
        }
    }
}

.background-mapa-produtos {
    background-image: url("imgs/mapa-produtos.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;

    .container-mapa-produtos {
        padding: 60px 0px;

        @media (max-width: 768px) {
            padding: 60px 15px;
        }

        .mapa-produtos-nv1 {
            font-size: 25px;
            color: #000;
            text-transform: uppercase;
        }

        .mapa-produtos-nv2 {
            color: #000;
            text-transform: uppercase;
            font-size: 39px;
            font-weight: bold;
            line-height: 35px;
            margin-bottom: 50px;
        }

        .btn-mapa-produtos {
            color: white;
            font-size: 15px;
            text-transform: uppercase;
            font-weight: 600;
            background-color: #e3342f;
            padding: 8px 50px;
        }
    }
}

.background-contato {
    background-image: url("imgs/banner-contato.png");
    background-repeat: no-repeat;
    background-size: cover;

    .container-entre-contato {
        padding: 60px 0px;

        @media (max-width: 768px) {
            padding: 60px 15px;
        }

        .entre-contato {
            padding-left: 80px;
            font-size: 26px;
            color: white;
            line-height: 40px;
            text-transform: uppercase;
            margin-bottom: 20px;

            @media (max-width: 768px) {
                padding-left: 0px;
            }

            .nv-2 {
                font-weight: bolder;
                font-size: 53px;
            }
        }

        .items-item-contrato {
            padding-left: 120px;
            font-size: 18px;
            color: white;
            text-transform: uppercase;

            @media (max-width: 768px) {
                padding-left: 40px;
            }
        }

        .btn-whatsapp {
            background-color: #00bfa5;
            font-size: 15px;
            font-weight: 600;
            padding: 8px 50px;
            margin-left: 120px;
            margin-top: 20px;
            text-transform: uppercase;
            color: white;

            @media (max-width: 768px) {
                margin-left: 40px;
                margin-top: 20px;
            }
        }
    }
}

.altura-mapa-contato {
    height: 400px;

    @media (max-width: 768px) {
        height: 250px;
    }
}
