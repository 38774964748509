.banner-seja-franqueado {
    padding: 80px 0;
    background-size: cover;
    background-position: center;

    .descricao {
        color: white;
        font-weight: bold;
        font-size: 35px;
        line-height: 40px;
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    .btn-seja-franqueado {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 10px;
        background-color: #00bfa5;
        color: white;
        padding: 10px 20px;
    }
}

.capitalize {
    text-transform: capitalize;
}

.divider-seja-franqueado {
    height: 100px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        height: 5px;
        width: 180px;
        top: 50%;
        left: calc(50% - 90px);
        background-color: #e3342f;
    }

    &.white::before {
        content: "";
        position: absolute;
        height: 5px;
        width: 180px;
        top: 50%;
        left: calc(50% - 90px);
        background-color: white;
    }
}

.sobre-empresa-franqueado {
    height: 250px;
    margin-bottom: 15px;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    float: left;

    &.n1 {
        width: calc(30% - 15px);
    }

    &.n2 {
        width: calc(70% - 15px);
    }

    &.n3 {
        width: calc(65% - 15px);
    }

    &.n4 {
        width: calc(35% - 15px);
    }
}

.nossa-historia-seja-franquiado {
    font-size: 15px;
    color: #515151;
}

.section-nossos-produtos-seja-franquiado {
    background-color: #e3342f;
}

.container-titulo-seja-franqueado-white {
    .nv-1 {
        text-transform: uppercase;
        color: white;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        font-weight: 600;
    }
}

.container-titulo-seja-franqueado {
    .nv-1 {
        text-transform: uppercase;
        color: black;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
        font-weight: 600;
    }
}

.container-planos {
    border-radius: 10px;
    border: 1px solid #e3342f;
    transition: 0.3s ease;
    margin-bottom: 20px;

    .titulo {
        text-align: center;
        border-bottom: 1px solid #e3342f;
        border-radius: 10px;
        font-size: 35px;
        font-weight: bolder;
        color: #e3342f;
        padding: 20px 0;

        transition: 0.3s ease;
    }

    .descricao {
        font-size: 15px;
        color: #515151;
        text-align: center;
        padding: 20px 90px;
        //height: 112px;
    }

    &:hover {
        -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.31);
        -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.31);
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.31);

        .titulo {
            background-color: #e3342f;
            color: white;
        }
    }
}

.btn-planos {
    padding: 5px 30px;
    font-size: 10px;
    font-weight: 900;
    color: white;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.31);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.31);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.31);
    background-color: #00bfa5;
    margin-bottom: 20px;

    &:hover {
        color: white;
    }
}

.titulo-franqueado-treinamento {
    text-align: center;
    font-size: 26px;
    color: #030303;
    margin-bottom: 30px;
}

.item-vantagem {
    .icone {
        color: #00bfa5;
        font-size: 36px;
        padding-right: 10px;

        margin-bottom: 20px;
    }

    .descricao {
        line-height: 20px;
        font-size: 15px;
    }
}

.item-vantagem-2 {
    margin-bottom: 20px;

    .icone {
        color: #3e3c3c;
        font-size: 46px;
        // margin-bottom: 20px;
        text-align: center;
    }

    .descricao {
        line-height: 20px;
        font-size: 15px;
        text-align: center;
    }
}

.titulo-marcar-parceiras {
    font-size: 30px;
    color: #949494;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.estado-container {
    max-height: 460px;
    overflow: auto;

    //@media (max-width: 768px) {
    //    height: 214px;
    //}

    &::-webkit-scrollbar {
        background: transparent;
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background: #e3342f;
        width: 5px;
    }

    .container-seja-franqueado {
        margin-bottom: 30px;

        .titulo {
            font-size: 22px;
            margin-bottom: 17px;
        }

        .item-form-contato {
            margin-bottom: 15px;

            .icone {
                i {
                    display: inline-block;
                    border-radius: 60px;
                    padding: 0.5em 0.6em;
                    background-color: #e3342f;
                    color: white;
                    font-size: 17px;
                    margin-right: 15px;
                }
            }

            .descricao {
                font-size: 15px;
                color: #515151;
            }
        }
    }
}

//Mapa styles
#svg-map path {
    fill: #fff;
    transition: 0.5s;
}

#svg-map text {
    fill: #e3342f;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
}

#svg-map a {
    text-decoration: none;
}

#svg-map a:hover {
    cursor: pointer;
    text-decoration: none;
}

#svg-map a:hover path {
    fill: #e3342f !important;
}

#svg-map a:hover text {
    fill: #fff !important;
}

#svg-map .circle {
    fill: transparent;
}

#svg-map a:hover .circle {
    cursor: pointer;
}

#svg-map .estado {
    &.active {
        path {
            fill: #e3342f !important;
        }

        text {
            fill: #fff !important;
        }
    }
}

.container-parceiros {
    border: 1px solid #707070;
    padding: 15px 0;
    margin-top: -1px;
    margin-left: -1px;
    overflow: hidden;

    img {
        //width: 100%;
    }
}
