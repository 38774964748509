.pre-requisitos {
    background-size: cover;
    background-position: center;
    background-color: #e3342f;

    .descricao {
        color: white;
        font-weight: bold;
        font-size: 35px;
        line-height: 40px;
        text-transform: uppercase;
        padding: 25px;
    }
}

.requisitos {
    margin: 20px 0 20px 0;

    .item-requisito {
        .icone {
            color: #3E3C3C;
            font-size: 46px;
            text-align: center;
            padding: 30px;
        }

        .descricao {
            line-height: 20px;
            font-size: 15px;
            text-align: center;
        }
    }
}

.importancia-texto {
    margin: 20px 20px 20px 20px;

    .descricao {
        color: #515151;
        font-weight: bold;
        font-size: 20px;
    }
}

.botao-produtos {
    border-radius: 50px;
    width: 600px;
    background-color: red;
    margin-top: 10px;

    @media (max-width: 576px) {
        width: 300px;
    }

    .descricao {
        color: white;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        text-transform: uppercase;
        padding: 10px;
    }
}

.botao-conheca {
    border-radius: 50px;
    width: 600px;
    background-color: #e3342f;
    margin-top: 10px;
    border-color: #e3342f !important;
    border: solid 1px;
    padding-left: 0px;
    padding-right: 0px;

    @media (max-width: 576px) {
        width: 300px;
    }

    .descricao {
        color: white;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        text-transform: uppercase;
        padding: 10px;
        margin-bottom: 0px !important;

        &:hover, &:focus, &.active {
            color: #e3342f !important;
            margin-bottom: 0px !important;
        }
    }

    &:hover, &:focus, &.active {
        background-color: white !important;
        color: #e3342f !important;
        transition: .3s;
        border-color: #e3342f !important;
        border: solid 1px;

    }
}

.btn-form {
    border-radius: 50px;
    width: 300px;
    background-color: #e3342f;
    border-color: #e3342f !important;
    border: solid 1px;
    padding-left: 0px;
    padding-right: 0px;

    .descricao {
        color: white;
        font-weight: bold;
        font-size: 14px;
        line-height: 40px;
        text-transform: uppercase;
        padding: 1px;
    }

    &:hover, &:focus, &.active {
        background-color: white !important;
        color: #e3342f !important;
        transition: .3s;
        border-color: #e3342f !important;
        border: solid 1px;

        .descricao {
            color: #e3342f !important;
            margin-bottom: 0px !important;
        }

        .far{
            color: #e3342f !important;
            margin-bottom: 0px !important;
        }

    }
}

.row-margin{
    margin-right: 0px !important;
    margin-left: 0px !important;
}
