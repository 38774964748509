.footer {
    background-color: black;

    .nv-1 {
        color: white;
        padding: 15px 0;

        .fone-footer-container {
            .fone-icon-footer {
                padding-right: 15px;

                i {
                    font-size: 25px;
                    color: #e3342f;
                }
            }

            .fone-footer {
                font-size: 11px;
                line-height: 15px;

            }
        }


        .media-footer-container {
            margin-bottom: 5px;


            .media-icon-footer {
                padding-right: 7px;

                img {
                    height: 20px;
                    width: 20px;
                    transition: 0.5s;

                }

                .youtube {
                    height: 24px !important;
                }


            }

            .media-footer {
                font-size: 10px;
                color: white;
                width: 82px;
            }

            &:hover {
                text-decoration-color: white !important;
                img {
                    transform: scale(1.2);
                }
            }
        }

    }

    .nv-2 {
        border-top: 1px solid white;
        color: white;
        font-size: 13px;

        padding: 15px 0;
        white-space: nowrap;

        .text {
            @media (max-width: 767px) {
                padding-bottom: 15px;
            }
        }

        @media (max-width: 767px) {
            white-space: normal;
        }

        a {
            color: white;
        }
    }
}
