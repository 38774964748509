
.sac-titulo {
    font-size: 33px;
    font-weight: bolder;
    color: #e3342f;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 30px;

    @media (max-width: 767px) {
        font-size: 18px;

    }
}

.sac-descricao {
    font-size: 18px;
    color: #000;
    margin-bottom: 30px;
    @media (max-width: 767px) {
        font-size: 14px;
    }
}

.btn-form-sac {
    background-color: #00BFA5;
    color: white;
    font-size: 15px;
    text-transform: uppercase;
    padding: 8px 18px;

    span {
        @media (max-width: 768px) {
            display: none;
        }
    }


    &:hover {
        color: white;
    }
}

.sac-formulario {

    input.form-control {
        border-color: #C7C7C7;
        height: 50px;
    }

}
