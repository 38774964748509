body {
    top: 25px !important;
}

.skiptranslate {
    display: none !important;
}

.goog-te-banner-frame {
    visibility: hidden !important;
    display: none !important;
}

.wrapper-bandeiras {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;

    @media (max-width: 992px) {
        justify-content: center;
    }

    .padding-bandeiras {
        padding-bottom: 15px;
        padding-top: 15px;
        text-align: center;
        list-style: none;
        margin-left: 15px;

        @media (min-width: 992px) {
            display: flex;
            align-items: center;
        }
    }

    .imagem-active {
        img {
            opacity: 1;
            margin: 0px 5px;
            width: 30px;
            height: 30px;
        }
    }

    .imagem-nao-active {
        img {
            opacity: 0.5;
            margin: 0px 5px;
            width: 30px;
            height: 30px;
        }
    }
}

.manuais-tecnicos {
    font-size: 0.9rem;
    color: rgba($color: #515151, $alpha: 0.6);

    transition: 0.3s;

    &:hover {
        color: #515151;
        text-decoration-color: #515151;
    }
}

.navbar-logo {
    @media (max-width: 900px) {
        justify-content: center !important;
    }
}

.navbar-brand {
    margin-right: 0px !important;
}

.navbar-mocelin {
    padding-left: 0px;
    padding-right: 0px;
    justify-content: space-between;

    .navbar-brand {
        display: none;
        margin-right: 0px !important;

        @media (max-width: 991.98px) {
            display: block;
        }

        img {
            height: 20px;
            widthi: 20px;
        }

        .youtube {
            width: 20px;
            height: 27px;
        }
    }

    .botao-categorias {
        background: #e3342f;
        color: white !important;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        padding: 8px 20px !important;
        border-radius: 4px;

        @media (max-width: 999px) {
            margin-top: 10px;
        }
    }

    .nav-item {
        .nav-link {
            font-size: 13px;
            font-weight: 600;
            color: #515151;
            padding: 8px 30px !important;
            position: relative;
            transition: 0.5s ease-in-out;
            text-transform: uppercase;

            @media (max-width: 1199px) {
                padding: 8px !important;
            }

            &::before {
                content: "";
                position: absolute;
                bottom: 0px;
                height: 2px;
                width: 0px;
                left: calc(50% - 10px);
                background-color: #e3342f;
                transition: 0.5s;
            }

            &:hover,
            &.active {
                color: #e3342f;

                @media (min-width: 999px) {
                    &::before {
                        width: 20px;
                    }
                }
            }
        }
    }

    .nav-item-icon {
        .nav-link {
            padding: 8px 3px !important;

            .youtube {
                height: 20px !important;
            }

            img {
                height: 15px;
                width: 15px;
                transition: 0.5s;
            }

            &:hover {
                img {
                    transform: scale(1.2);
                }
            }
        }

        @media (max-width: 991.98px) {
            display: none;
        }
    }
}

.btn-login {
    background: #f29100;
    color: white;
    text-transform: uppercase;
    padding-right: 20px;
    padding-left: 20px;
    white-space: nowrap;

    &:hover {
        color: #fff;
    }

    @media (max-width: 500px) {
        padding: 3px 10px !important;
        font-size: 14px;
    }
}

.dropdown-login {
    .dropdown-item {
        &:focus {
            color: #fff;
            background: #f29100;
        }
    }
}

.btn-cart {
    position: relative;
    font-size: 25px;
    text-align: center;
    color: #e3342f;

    span {
        position: absolute !important;
        font-size: 10px;
        top: 3px !important;
        right: 3px;
    }

    &:hover {
        color: #e3342f;
    }
}

.divider-cabecalho {
    height: 1px;
    width: 100%;
    margin-bottom: 8px;
    background-color: #e9e9e9;
}

.input-busca-cabecalho {
    width: 100%;

    .input-group {
        @media (max-width: 992px) {
            width: 100%;
        }
    }

    input {
        //@media (max-width: 992px) {
        //    display: inline-block !important;
        //    vertical-align: middle !important;
        //
        //    position: relative;
        //    flex: 1 1 auto;
        //    width: 1%;
        //    min-width: 0;
        //    margin-bottom: 0;
        //}

        @media (max-width: 500px) {
            width: 115px !important;
            height: 30px !important;
            font-size: 11px;
        }
    }
}

.btn-busca-cabecalho-mobile {
    color: #e3342f;

}

.btn-busca-cabecalho {

    @media (max-width: 500px) {
        padding: 3px 10px !important;
        font-size: 14px;
    }
}

.dropdown-category {
    width: 950px !important;

    @media (max-width: 500px) {
        width: auto !important;
    }
}
