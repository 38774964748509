.divider-planos {
    height: 30px;
    position: relative;

    margin-bottom: 20px;


    &::before {
        content: "";
        position: absolute;
        height: 10px;
        width: 180px;
        top: 50%;
        left: calc(50% - 90px);
        background-color: #e3342f;
    }

    &.white::before {
        content: "";
        position: absolute;
        height: 5px;
        width: 180px;
        top: 50%;
        left: calc(50% - 90px);
        background-color: white;
    }
}

.container-titulo-planos {
    .nv-1 {
        color: #e3342f;
        font-weight: bolder;
        font-size: 35px;
        text-align: center;
        margin-bottom: 20px;
    }
}

.container-descricao-planos {
    text-align: center;
    font-size: 18px;
    margin-bottom: 25px;
}

.container-itens-planos {
    border-radius: 5px;
    padding: 25px;
    margin-bottom: 30px;


    .item {
        margin-bottom: 20px;

        .imagem {
            padding-right: 20px;
            width: 60px !important;
            text-align: center;
        }

        .desc {
            color: #000;
            line-height: 20px;

            .n1 {
                text-transform: uppercase;
                font-size: 15px;

            }

            .n2 {
                font-size: 20px;
                font-weight: bolder;
            }

        }
    }
}

.btn-quero-investir {
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: bolder;
    color: white;
    background-color: #00BFA5;
    padding: 8px 20px;

    &:hover {
        color: white;
    }

}
