.card-login {
    margin-bottom: 80px;

    .card-login-header {
        text-align: center;
        border-bottom: 1px solid #707070;
        padding-bottom: 15px;
        margin-bottom: 15px;
        font-size: 20px;
        font-weight: bold;

        img {
            padding: 0px 15px;
        }
    }

    .card-login-body {
        border-bottom: 1px solid #707070;
        margin-bottom: 20px;

        a {
            color: #0033ff;
            line-height: 30px;
        }

        .form-group {
            label {
                font-size: 15px;
                color: #000;
                padding: 0px;
            }

            .form-control {
                border-radius: 0px !important;
            }

            .select2-selection {
                border-radius: 0px !important;
            }
        }
    }

    .btn-padrao {
        border-radius: 2px;
        width: 100%;
        background: #e3342f;
        margin: 20px 0;
    }

    .card-login-footer {
        text-align: center;
        padding-bottom: 15px;
        margin-bottom: 15px;
        font-size: 20px;
        font-weight: bold;
    }
}
