.background-busca-produtos {
    background-color: #e3342f;
    color: white;
    font-weight: bolder;
    font-size: 19px;
    padding: 20px 0px;

    .form-group {
        margin-bottom: 0px;
        .form-control {
            height: 44px;
            border-color: #e3342f;
        }
    }
}

.slide-wrapper-categoria {
    padding: 0 15px;
}

.container-categorias-produtos {
    text-align: center;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(112, 112, 112, 0.39);
    -moz-box-shadow: 0px 3px 5px 0px rgba(112, 112, 112, 0.39);
    box-shadow: 0px 3px 5px 0px rgba(112, 112, 112, 0.39);
    border-radius: 5px;
    border: 2px solid transparent;
    transition: all 0.2s ease;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;

    &:hover,
    &.active {
        border: 2px solid #c90000;
        -webkit-box-shadow: 0px 3px 5px -24px rgba(112, 112, 112, 0.39);
        -moz-box-shadow: 0px 3px 5px -24px rgba(112, 112, 112, 0.39);
        box-shadow: 0px 3px 5px -24px rgba(112, 112, 112, 0.39);

        // .imagem-categorias {
        //     // background-position-y: -140px;
        //     filter: grayscale(0%);
        // }

        .titulo-categorias {
            color: rgba($color: #6e6e6e, $alpha: 1);
        }
    }

    .imagem-categorias {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px auto;
        background-position: center;
        transition: all 0.2s ease;
        // filter: grayscale(100%);
        background-repeat: no-repeat;
    }

    .titulo-categorias {
        font-weight: 600;
        font-size: 12px;
        color: rgba($color: black, $alpha: 0.85);
        line-height: 15px;
        margin-bottom: 5px;
        // min-height: 45px;
    }
}

.menu-categorias {
    .titulo-menu-categorias {
        font-size: 2.25rem;
        color: #030303;
        font-weight: 500;
        margin-bottom: 30px;
    }

    .menu {
        list-style: none;
        padding: 0;

        & > li {
            font-size: 18px;
            margin-bottom: 8px;

            a {
                transition: 0.3s;
                color: #030303;
                text-decoration: none;

                &.active {
                    color: #ff0000;
                    font-weight: 600;
                }

                &:hover {
                    color: #ff0000;
                }
            }

            .submenu {
                padding-left: 20px;
                list-style: none;
                margin-top: 8px;

                li {
                    font-size: 14px;
                    font-weight: 400;
                    margin-bottom: 5px;

                    a {
                        color: rgba($color: #030303, $alpha: 0.4);
                        transition: 0.3s;

                        &.active {
                            color: #ff0000;
                            font-weight: 600;
                        }

                        &:hover {
                            color: #ff0000;
                        }
                    }
                }
            }
        }
    }
}

.background-produtos {
    background-color: #fff;
    padding: 80px 0px;

    @media (max-width: 900px) {
        padding: 40px 0px;
    }

    .col-3 {
        border-right: 1px solid rgba($color: #c2c2c2, $alpha: 0.3);
    }

    // .lista-produtos {
    //     .produtos-container-home {
    //         .descricao-container {

    //         }
    //     }
    // }

    .container-titulo-produtos {
        font-weight: 600;
        font-size: 30px;
        color: #030303;
        margin-bottom: 30px;
    }

    .produtos-container {
        border-radius: 10px;
        margin-bottom: 20px;
        transition: 0.5s;
        will-change: transform;

        &:hover {
            -webkit-box-shadow: 0px 4px 9px 2px rgba(207, 207, 207, 1);
            -moz-box-shadow: 0px 4px 9px 2px rgba(207, 207, 207, 1);
            box-shadow: 0px 4px 9px 2px rgba(207, 207, 207, 1);
            transform: translateY(-5px);
            transform: scale(1.02);
        }

        @media (max-width: 900px) {
            margin-bottom: 25px;
        }

        .produto-imagem-container {
            height: 180px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;

            .sem-imagem {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 11px;
                background-color: #f2f2f2;
                color: #a8a8a8;
                text-align: center;
                height: 100%;

                i {
                    font-size: 30px;
                }
            }
        }

        .descricao-container {
            padding: 8px;
            padding-top: 15px;

            .titulo {
                text-align: center;
                color: #676767;
                font-size: 12px;
                line-height: 17px;
                margin-bottom: 25px;
                text-transform: uppercase;
                min-height: 34px;
            }

            .saiba-mais {
                text-align: center;
                text-decoration: underline;
                text-transform: uppercase;
                font-weight: 600;
                color: #e3342f;
                font-size: 14px;
                margin-bottom: 15px;

                .btn-orcamento {
                    background-color: #e3342f;
                    color: #fff;
                    font-weight: 600;
                    font-size: 8px;
                }
            }
        }
    }
}
