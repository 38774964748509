.dados-candidato {
    background-size: cover;
    background-position: center;
    background-color: #e3342f;
    margin-bottom: 20px;

    .descricao {
        color: white;
        font-weight: bold;
        font-size: 16px;
        line-height: 40px;
        text-transform: uppercase;
        padding: 4px;
    }
}


.content-start {
    justify-content: flex-start !important;

    @media (max-width: 576px) {
        justify-content: center !important;
    }
}

.content-end {
    justify-content: flex-end !important;

    @media (max-width: 576px) {
        justify-content: center !important;
    }
}
