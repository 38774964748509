.btn-contato {
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    margin-bottom: 20px;
    border-color: #e3342f;
    text-transform: uppercase;
    color: #e3342f;

    &:hover, &:focus, &.active {
        border-color: #e3342f !important;
        background-color: #e3342f !important;
    }
}

.container-titulo-contato {
    .nv-1 {
        text-transform: uppercase;
        color: black;
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
    }
}


.container-contato {
    margin-bottom: 30px;

    .container-img {
        height: 170px;
        width: 170px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 100%;
        margin: 0 auto;
        margin-bottom: 10px;
        border: 1px solid #707070;
    }

    .container-descricao {

        text-align: center;


        .nome {

            font-size: 15px;
            color: #676767;
            text-transform: uppercase;
            margin-bottom: 7px;

        }

        .celular {

            font-size: 12px;
            color: #676767;
            text-transform: uppercase;
            margin-bottom: 4px;

            img {
                width: 18px;
                height: 18px;
            }

        }

        .email {
            font-size: 12px;
            color: #676767;
            text-transform: uppercase;

        }
    }
}


.section-contato-form {
    background: #F8F8F8;
    padding: 80px 0;

    .titulo {
        font-size: 30px;
        font-weight: 600;
        color: black;
        margin-bottom: 20px;
    }

    .btn-form-contato {
        background-color: #00BFA5;
        color: white;
        font-size: 15px;
        text-transform: uppercase;
        padding: 8px 18px;
        margin: 2px 0;

        span {
            @media (max-width: 768px) {
                display: none;
            }
        }


        i.fa {
            display: inline-block;
            border-radius: 60px;
            box-shadow: 0px 0px 2px #888;
            padding: 0.5em 0.6em;

        }
    }

    .form-control {
        border: none;


        &::placeholder {
            color: rgba($color: #949494, $alpha: 0.5);
            opacity: 1;
            font-size: 15px;

            /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba($color: #949494, $alpha: 0.5);
            font-size: 15px;

        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba($color: #949494, $alpha: 0.5);
            font-size: 15px;
        }

    }

    input.form-control {
        height: 50px;
    }

    select.form-control {

    }

    span.select2 {
        .selection {
            outline: none !important;

            .select2-selection {
                height: 50px;
                border: none;
                outline: none !important;
                padding: 0px 12px;

                .select2-selection__rendered {
                    line-height: 50px;
                    height: 50px;
                    color: rgba($color: #949494, $alpha: 0.5);
                    outline: none;
                }

                .select2-selection__arrow {
                    height: 50px;

                    b {
                        border-color: rgba($color: #949494, $alpha: 0.5) transparent transparent transparent;
                    }
                }
            }
        }
    }

    .item-form-contato {

        padding-left: 40px;
        margin-bottom: 15px;

        @media (max-width: 767px) {

            margin-top: 20px;
            padding-left: 0px;
        }

        .icone {
            i {
                display: inline-block;
                border-radius: 60px;
                padding: 0.5em 0.6em;
                background-color: #e3342f;
                color: white;
                font-size: 17px;
                margin-right: 15px;
            }
        }

        .descricao {
            font-size: 15px;
            color: #515151;
            white-space: normal;

            @media (max-width: 767px) {
                font-size: 11px;
            }

        }

    }


}

//
//.select2-dropdown {
//    border: none !important;
//
//    .select2-search__field {
//
//        &:focus {
//            outline: none !important;
//        }
//    }
//}
