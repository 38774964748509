.titulo-carrinho {
    font-size: 20px;
    color: #000;
    font-weight: 900;
    margin-top: 40px;
    margin-bottom: 40px;
}


.container-resumo-pedido {
    padding: 15px;
    background: #F8F8F8;
    margin-bottom: 20px;

    .titulo {
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 8px;
    }

    .itens {
        min-height: 150px;
        margin-bottom: 7px;

        .item {
            display: flex;


            .titulo-item {
                font-size: 13px;
                flex: 100%;

            }

            .preco {
                font-size: 13px;
                font-weight: bold;
                white-space: nowrap;

            }
        }


    }

    .total {
        border-top: 1px solid #707070;
        display: flex;
        padding: 15px 0;

        .titulo-item {
            font-size: 13px;
            font-weight: bold;
            flex: 100%;
        }

        .preco {
            font-size: 13px;
            font-weight: bold;
            white-space: nowrap;
        }
    }

    .finalizar {


        button {
            width: 100%;
            background: #e3342f;
            color: white;
            font-weight: 600;
            font-size: 12px;
            text-transform: uppercase;
        }


    }


}

.titulo-status {
    font-weight: bolder;
    font-size: 16px;
    color: #000;
}

.descricao-status {
    font-size: 12px;
    color: #000;
    display: flex;
    align-items: center;

    i {
        color: #0E7D02;
        font-size: 20px;
        padding-left: 20px;
    }
}
