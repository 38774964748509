@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

//@import "../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
//@import "../../../node_modules/@fortawesome/fontawesome-free/scss/regular";
//@import "../../../node_modules/@fortawesome/fontawesome-free/scss/solid";
//@import "../../../node_modules/@fortawesome/fontawesome-free/scss/brands";

// Variables
@import "variables";

// Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap";

//Modulos externos
//tiny slider
@import "../../../node_modules/tiny-slider/src/tiny-slider";
//Lightgalery
@import "../../../node_modules/lightgallery/src/sass/lightgallery";
//select2
@import "../../../node_modules/select2/dist/css/select2.css";
@import "../../../node_modules/select2-bootstrap-theme/dist/select2-bootstrap.css";

@import "home";
@import "cabecalho";
@import "rodape";
@import "empresa";
@import "produtos";
@import "contato";
@import "seja-colaborador";
@import "seja-franqueado";
@import "seja-representante";
@import "produto";
@import "login";
@import "carrinho";
@import "sac";
@import "planos";
// @import "tiny-slider";
@import "owl-carousel";
@import "button-whatsapp";

title {
    text-transform: capitalize;
}

.mini-banner-width {
    width: 340px !important;

    @media (max-width: 400px) {
        width: 215px !important;
    }

    @media (min-width: 401px) and (max-width: 900px) {
        width: 240px !important;
    }
}

#load {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: white;
    font-size: 60px;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    color: red;
}

a {
    text-decoration: none !important;
    color: black;
}

.mb-80 {
    margin-bottom: 80px;

    @media (max-width: 900px) {
        margin-bottom: 40px;
    }
}

.mt-80 {
    margin-top: 80px;

    @media (max-width: 900px) {
        margin-top: 40px;
    }
}

.mb-50 {
    margin-bottom: 50px;

    @media (max-width: 900px) {
        margin-bottom: 25px;
    }
}

.mt-50 {
    margin-top: 50px;

    @media (max-width: 900px) {
        margin-top: 25px;
    }
}

.form-control {
    border-color: rgba($color: #949494, $alpha: 0.5);

    &::placeholder {
        color: rgba($color: #949494, $alpha: 0.5);
        opacity: 1;
        /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba($color: #949494, $alpha: 0.5);
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba($color: #949494, $alpha: 0.5);
    }
}

.btn-padrao {
    background-color: #e3342f;
    border-color: #e3342f;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    padding-right: 15px;
    padding-left: 15px;

    &:hover {
        color: white;
    }
}

.no-padding {
    padding: 0px;
}

.padr-5 {
    padding-right: 5px;
    @media (max-width: 768px) {
        padding-right: 0px;
    }
}

.padding-5 {
    padding: 0 5px;
}

.padding-left-5 {
    padding-left: 5px;
}

.padding-bottom-5 {
    padding-bottom: 5px;
}

.whatsapp-fixed {
    position: fixed;
    bottom: 50px;
    right: 50px;

    @media (max-width: 1000px) {
        bottom: 15px;
        right: 15px;

        img {
            height: 40px;
            width: 40px;
        }
    }
}

.titulo-secundarias {
    font-size: 42px;
    font-weight: 900;
    color: white;
    text-align: center;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-bottom: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    text-transform: uppercase;
}

.container-titulo-left {
    line-height: 42px;
    color: #0c0100;
    margin-bottom: 60px;
    text-align: left;
    text-transform: uppercase;

    @media (max-width: 900px) {
        line-height: 25px;
    }

    @media (max-width: 900px) {
        margin-bottom: 40px;
    }

    .nv-1 {
        font-size: 25px;
        font-weight: 900;

        @media (max-width: 900px) {
            font-size: 13px;
        }
    }

    .nv-2 {
        font-size: 57px;
        font-weight: 900;

        @media (max-width: 900px) {
            font-size: 25px;
        }
    }
}

.botoes-flutuantes {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99999;
    line-height: 0;
    display: block;
    //width: 170px;

    a {
        i {
            border-radius: 60px;
            background-color: #25d366;
            color: white;
            line-height: 60px;
            text-align: center;
            font-size: 40px;
            height: 60px;
            width: 60px;
        }
    }

    //.btn-flutuante-whatsapp {
    //    width: 100%;
    //    height: 45px;
    //    line-height: 45px;
    //    display: block;
    //    // float: left;
    //    border-radius: 30px;
    //    background: #66e094;
    //    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    //    text-align: center;
    //    transition: all 0.5s ease;
    //    color: white;
    //    text-decoration: none;
    //
    //    &:hover {
    //        background: #25d366;
    //    }
    //}
}

//.card {
//    margin-bottom: 40px;
//
//    .card-header {
//        background-color: #e3342f;
//        color: white;
//    }
//}

.breadcrumb {
    background-color: white;

    .breadcrumb-item {
        color: black;
        font-size: 13px;

        a {
            font-size: 13px;
            color: black;
        }
    }
}

.mobile-infos {
    @media (max-width: 900px) {
        display: flex;
        justify-content: center;
        text-align: center;
    }
}

.flex-justify-mobile {
    display: flex;

    @media (max-width: 900px) {
        justify-content: center;
    }
}

.quantidade-produto {
    button {
        border-color: #ccc;
        color: black;

        // &:hover {
        //     background-color: #e3342f;
        //     color: white;
        // }
    }

    input {
        border-color: #ccc;
        text-align: center;
        color: black;
        background: white !important;
    }
}

.remove-margin {
    margin: 0px !important;
}

.produto-individual {
    @media (max-width: 900px) {
        .quantidade-produto-card {
            padding-right: 10px !important;
        }
        justify-content: center !important;
    }
}

.quantidade-produto-card {
    padding: 0px !important;
    padding-right: 10px !important;

    @media (max-width: 900px) {
        padding-right: 0px !important;
        // margin-bottom: 5px !important;
    }

    button {
        // width: 28px;
        border-top-color: black;
        border-left-color: black;
        border-right-color: black;
        border-bottom-color: black;
        background: white !important;
        // color: #e3342f;

        // &:hover {
        //     background-color: #e3342f;
        //     color: white;
        // }
    }

    input {
        width: 25px !important;
        height: 38.13px;
        border-top-color: black;
        border-bottom-color: black;
        border-left: none !important;
        border-right: none !important;
        text-align: center;
        background: white !important;

        // &:focus {
        //     border-color: #e3342f;
        //     box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
        // }
    }
}

.table-carrinho {
    .img-produto {
        height: 50px;
        object-fit: cover;
    }

    thead {
        tr {
            th {
                //font-size: 10px;
                border: 0px;
            }

            border-bottom: 1px solid #bbbbbb;
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid #bbbbbb;

            td {
                display: flex;
                align-items: center;
                border: none;
                padding: 7px 3px;

                @media (max-width: 900px) {
                    padding: 8px 18px;
                }
            }

            .total {
                font-size: 22px;
                font-weight: 900;
            }
        }
    }
}

.card-area-restrita {
    border-color: transparent;

    .card-header {
        background: white;
        border-color: transparent;
        padding: 0px;
        margin-bottom: 10px;

        .btn-link {
            font-size: 25px;
            font-weight: 600;
            color: #030303;
            text-decoration: none;
        }
    }

    .card-body {
        padding: 0px;

        .list-group {
            padding: 0px;

            .list-group-item {
                padding: 5px;
                border: none;

                &.active {
                    background: white;

                    .btn-link {
                        color: #e3342f;
                        font-weight: bolder;

                        i {
                            color: #e3342f;
                        }
                    }
                }

                .btn-link {
                    color: #030303;
                    font-size: 15px;
                    text-decoration: none;
                    display: flex;
                    align-items: center;

                    i {
                        color: #7e7e7e;
                        font-size: 20px;
                        padding-right: 20px;
                        width: 40px;
                    }
                }
            }
        }
    }
}

.titulo-area-restrita {
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 20px;
}

.table-area-restrita {
    //border-radius: 100px;

    thead {
        //border-radius: 100px;

        tr {
            background: #f2f2f2;
            //border-radius: 100px;

            th {
                border: none;

                &:first-child {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

                &:last-child {
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            td {
            }
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid #f2f2f2;

            th {
                border: none;
            }

            td {
                //padding: 0;
                border: none;
                vertical-align: middle;

                &.valor {
                    white-space: nowrap;
                }
            }
        }
    }
}

.facebook {
    padding: 5px;

    i {
        font-size: 25px;
        color: #4267b2;
    }
}

.twitter {
    padding: 5px;

    i {
        font-size: 25px;
        color: #1da1f2;
    }
}

.whatsapp {
    padding: 5px;

    i {
        font-size: 25px;
        color: #3ebe4f;
    }
}

.main {
    min-height: calc(100vh - 313px);
}

.select2 {
}

.g-recaptcha {
    @media (max-width: 350px) {
        width: 250px !important;
    }

    & > div {
        @media (max-width: 350px) {
            width: 250px !important;
        }

        iframe {
            @media (max-width: 350px) {
                width: 250px !important;
            }
        }
    }
}

.esconder-lista {
    display: block;
    @media (max-width: 768px) {
        display: none;
    }
}

.esconder-lista-carrocel {
    display: none;
    position: relative;
    @media (max-width: 768px) {
        display: block;
    }
}

.pagination {
    justify-content: flex-end;

    .page-item {
        transition: 0.5s;

        &.active,
        &:hover,
        &:focus {
            .page-link {
                transition: 0.5s;

                background-color: #e3342f;
                color: #fff;
            }
        }

        .page-link {
            color: #e3342f;
            border: 1px solid #e3342f;
        }
    }
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #e3342f;
    border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #e3342f;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
