.container-info-produto {
    .titulo-produto-individual {
        font-size: 26px;
        color: #000000;

        @media (max-width: 768px) {
            margin-top: 10px;
        }
    }

    .btn-compartilhar-produto {
        border: none;
        background-color: transparent;
        color: #e3342f;
        font-size: 16px;
    }

    .form-group {
        label {
            color: #e3342f !important;
        }
    }

    .valor {
        font-size: 35px;
        font-weight: 600;
        color: #e3342f;

        span {
            font-size: 20px;
        }

        a {
            color: #e3342f;
        }
    }

    .parcelamento {
        color: #949494;
        margin-bottom: 30px;
        font-size: 14px;
    }

    .informacoes {
        color: #949494;
        font-size: 16px;

        .ancora-mais-info {
            color: #e3342f;
        }
    }

    .pesos-produto {
        display: flex;

        @media (max-width: 900px) {
            justify-content: center;
        }

        .peso {
            font-size: 13px;
            color: #000;
            padding-right: 10px !important;

            @media (max-width: 900px) {
                padding: 10px !important;
            }

            span {
                text-align: center;
                border: solid 1px #e3342f;
                border-radius: 100px;
                padding: 5px;
                text-align: center;
                color: #e3342f;
                display: inline-block;
                min-width: 70px;
            }
        }
    }

    .btn-adicionar-carrinho {
        background-color: #00bfa5;
        color: white;
        font-weight: bold;
    }
}

.titulo-produtos-relacionados {
    font-size: 22px;
    color: #000;
}

.info-produto {
    font-size: 16px;
    color: #949494;
}

.btn-ficha-tecnica {
    border-radius: 50px;
    background-color: #e3342f;
    // font-weight: 600;
}

.slide-wrapper-container {
    // margin: 0px 25px;

    // @media (max-width: 768px) {
    //     margin: 0px 55px;
    // }

    .slide-wrapper {
        margin: 10px 0px;

        .container-produtos-relacionados {
            border: 1px solid #ebebeb;
            -webkit-box-shadow: 0px 5px 5px 1px rgba(235, 235, 235, 1);
            -moz-box-shadow: 0px 5px 5px 1px rgba(235, 235, 235, 1);
            box-shadow: 0px 5px 5px 1px rgba(235, 235, 235, 1);
            border-radius: 10px;
            margin-right: 7px;
            margin-left: 7px;

            transition: 0.5s;

            &:hover {
                transform: translateY(-5px);
                transform: scale(1.02);

                .descricao-container {
                    .titulo {
                        text-decoration: none !important;
                    }

                    .saiba-mais {
                        text-decoration: underline;
                    }
                }
            }

            .imagem {
                height: 300px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;

                .sem-imagem {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    background-color: #f2f2f2;
                    color: #a8a8a8;
                    text-align: center;
                    height: 100%;

                    i {
                        font-size: 50px;
                    }
                }

                @media (max-width: 768px) {
                    height: 150px;
                }
            }

            .descricao {
                padding: 20px 50px;

                @media (max-width: 768px) {
                    padding: 2px 12px;
                }

                text-align: center;

                .titulo {
                    color: #676767;
                    font-size: 12px;
                    min-height: 75px;
                }

                .valor {
                    font-size: 15px;
                    color: #000000;
                    font-weight: 600;
                }

                .parcelamento {
                    font-size: 13px;
                    color: #949494;
                }
            }
        }
    }
}

#imagem-destaque {
    border: 1px solid rgba(178, 178, 178, 0.62);
    height: 450px;
    margin-bottom: 30px;
    object-fit: contain;

    @media (max-width: 768px) {
        height: 300px;
    }
}

.slide-wrapper-galeria-produto {
    .container-imagem-galeria {
        margin-right: 7px;
        margin-left: 7px;
        margin-bottom: 15px;

        .imagem {
            height: 400px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            cursor: pointer;

            .sem-imagem {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                background-color: #f2f2f2;
                color: #a8a8a8;
                text-align: center;
                height: 100%;

                i {
                    font-size: 75px;
                }
            }
        }
    }
}

.slide-wrapper-galeria-produto2 {
    .container-imagem-galeria {
        margin-right: 7px;
        margin-left: 7px;

        .imagem {
            height: 90px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        &.active {
            border: 1px solid rgba(178, 178, 178, 0.62);
        }
    }
}

.next-galeria-produto {
    width: 30px;
    height: 30px;
    color: #c5004a;
    font-size: 17px;
    border-radius: 100%;
    background: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    position: absolute;
    top: calc(50% - 15px);
    right: 0px;
    z-index: 999;

    @media (max-width: 768px) {
        right: 0px;
    }
}

.prev-galeria-produto {
    width: 30px;
    height: 30px;
    color: #c5004a;
    background: white;
    font-size: 17px;
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    position: absolute;
    top: calc(50% - 15px);
    left: 0px;

    z-index: 999;

    @media (max-width: 768px) {
        left: 0px;
    }
}

.prev {
    width: 30px;
    height: 30px;
    color: #c5004a;
    background: white;
    font-size: 17px;
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    position: absolute;
    top: calc(50% - 15px);
    left: 0px;
    z-index: 999;

    @media (max-width: 768px) {
        left: 0px;
    }
}

.next {
    width: 30px;
    height: 30px;
    color: #c5004a;
    font-size: 17px;
    border-radius: 100%;
    background: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    position: absolute;
    top: calc(50% - 15px);
    right: 0px;
    text-align: right;
    z-index: 999;

    @media (max-width: 768px) {
        right: 0px;
    }
}

.variacao {
    color: #e3342f;
    border-color: #e3342f !important;

    &:focus,
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
    }
}
